import { Club } from "../types";

export default function getPersonalizedClubs(): Club[] {
  return [
    {
      id: process.env.REACT_APP_CLUB_ID_PERSONALIZADO_200,
      hasException: false,
      description: 'Clube personalizado com serviços específicos solicitados pelo cliente',
      name: 'Clube Personalizado 200',
      price: '200,00',
      services: [
        'Preços exclusivos em outros serviços',
        'Renovação mensal',
      ],
      type: 'nail',
    },
  ];
}
